import './style.css';
const WaveLoader = () => {
    return(
        <div className="waveloader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        )
}
export default WaveLoader;